import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, ErrorHandler, isDevMode } from '@angular/core';
import { AppComponent } from './components';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// import 'zone.js/dist/zone-patch-rxjs';
import { CoreModule, LabelService, RollbarErrorHandler } from 'app/core';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { AppRoutes } from './app.routing';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireFunctionsModule, ORIGIN } from '@angular/fire/compat/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { NgxMaskModule } from 'ngx-mask';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import 'hammerjs';
import { MAT_DATE_LOCALE } from '@angular/material/core';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { AppCheckInterceptorService } from '@core/services/app-check-interceptor.service';
// import { AppCheckService } from '@core/services/app-check.service';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
// import { provideAppCheck } from '@angular/fire/app-check';
// import { CustomReuseStrategy } from './custom-route-reuse';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    RecaptchaV3Module,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    RouterModule.forRoot(AppRoutes, { preloadingStrategy: PreloadAllModules, }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxMaskModule.forRoot(),
    HammerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: LabelServiceFactory,
      deps: [LabelService],
      multi: true
    },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAppCheck(() => {
    //   return initAppCheck();
    // }),
    {
      provide: ErrorHandler,
      useClass: RollbarErrorHandler
    },
    {
      provide: ORIGIN,
      useValue: ''
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.v3SiteKey,
    },
    { provide: MAT_DATE_LOCALE, useValue: navigator.languages[0] },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AppCheckInterceptorService,
    //   multi: true,
    // },
    // { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function LabelServiceFactory(labelService: LabelService) {
  return () => labelService.init();
}

// function initAppCheck() {
//   const appCheck = inject(AppCheckService);
//   return appCheck.initAppCheck();
// }