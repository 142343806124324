import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@core/services';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  public constructor(
    private router: Router,
    private userService: UserService
  ) { }

  public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      try {
        const userData = await this.userService.getCurrentUserData().pipe(take(1)).toPromise();

        if (!userData?.permissions) {
          this.router.navigateByUrl('/');
          resolve(false);
        } else {
          resolve(true);
        }
      } catch (e) {
        resolve(false);
      }
    });
  }
}
